var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "declarao-ppe" },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "h4",
          { staticClass: "vx-col w-full flex items-center font-semibold p-0" },
          [
            _c("span", { staticClass: "block" }, [
              _vm._v("Auto declaração PPE"),
            ]),
            _c(
              "vx-tooltip",
              { attrs: { text: _vm.text.textPPE, position: "right" } },
              [
                _c("i", { staticClass: "material-icons pl-2" }, [
                  _vm._v("info"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c("p", [
          _vm._v(
            "\n    É considerada um Pessoa Politicamente Exposta (PPE) ou Pessoa Exposta Politicamente (PEP), aquela que desempenha ou desempenhou nos últimos cinco anos anteriores, no Brasil ou em países, territórios e dependências estrangeiros, cargos, empregos ou funções públicas relevantes.\n    "
          ),
        ]),
        _c("p", [_vm._v("Algum dos sócios se enquadra como PPE?")]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mt-5 pl-0" },
          [
            _c(
              "vs-switch",
              {
                staticClass: "w-1/12",
                model: {
                  value: _vm.cadastro.possuiPep,
                  callback: function ($$v) {
                    _vm.$set(_vm.cadastro, "possuiPep", $$v)
                  },
                  expression: "cadastro.possuiPep",
                },
              },
              [
                _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                  _vm._v("Sim"),
                ]),
                _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                  _vm._v("Não"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.cadastro.possuiPep
        ? _c("div", { staticClass: "vx-row mt-5" }, [
            _vm._m(0),
            _c("p", [
              _vm._v(
                "Cadastre abaixo a lista dos sócios que se enquadra como PPE."
              ),
            ]),
          ])
        : _vm._e(),
      _vm.cadastro.possuiPep
        ? [
            _vm._l(_vm.cadastro.QSAs, function (socio, index) {
              return _c("div", { key: index, staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0",
                  },
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "Nome", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    "label-placeholder": "Nome",
                                    size: "large",
                                  },
                                  model: {
                                    value: socio.Name,
                                    callback: function ($$v) {
                                      _vm.$set(socio, "Name", $$v)
                                    },
                                    expression: "socio.Name",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: errors[0],
                                        expression: "errors[0] ",
                                      },
                                    ],
                                    staticClass: "text-danger text-sm",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(errors[0]) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col md:w-4/12 w-full mt-5 md:pr-0 md:pl-2 p-0",
                  },
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "CPF", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "###.###.###-##",
                                      expression: "'###.###.###-##'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    "label-placeholder": "CPF",
                                    size: "large",
                                  },
                                  model: {
                                    value: socio.CpfCnpj,
                                    callback: function ($$v) {
                                      _vm.$set(socio, "CpfCnpj", $$v)
                                    },
                                    expression: "socio.CpfCnpj",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: errors[0],
                                        expression: "errors[0] ",
                                      },
                                    ],
                                    staticClass: "text-danger text-sm",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(errors[0]) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col mt-10 pr-0" },
                  [
                    _c("vs-button", {
                      staticClass: "delete",
                      attrs: {
                        color: "primary",
                        type: "flat",
                        size: "large",
                        icon: "delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeSocio(index)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "font-semibold mt-2 float-right",
                      attrs: {
                        "icon-after": "",
                        color: "primary",
                        type: "flat",
                        icon: "add_circle",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addSocio()
                        },
                      },
                    },
                    [_vm._v("\n          Adicionar sócio\n        ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h4",
      { staticClass: "vx-col w-full flex items-center font-semibold p-0" },
      [
        _c("span", { staticClass: "block" }, [
          _vm._v("Sócios/Administradores PEP"),
        ]),
        _c("i", { staticClass: "material-icons pl-2" }, [_vm._v("info")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }