var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content w-full lg:w-10/12 xl:w-7/12" },
    [
      _c(
        "custom-card",
        { staticClass: "card" },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return [
                    _c(
                      "form-wizard",
                      {
                        attrs: {
                          color: "rgba(var(--vs-warning), 1)",
                          title: null,
                          subtitle: null,
                          finishButtonText: "Submit",
                        },
                        on: { "on-complete": _vm.formSubmitted },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "footer",
                              fn: function (props) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-12 inline-flex w-full justify-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "wizard-footer-left" },
                                        [
                                          props.activeTabIndex > 0
                                            ? _c(
                                                "vs-button",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    type: "flat",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return props.prevTab()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "onpoint-arrow-left icon-font",
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-semibold pl-4",
                                                    },
                                                    [_vm._v("Voltar")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "wizard-footer-right" },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                disabled: invalid,
                                                color: "primary",
                                                type: "flat",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.avancarPagina()
                                                    ? props.nextTab()
                                                    : ""
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-semibold pr-4",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        props.isLastStep
                                                          ? "Concluir cadastro"
                                                          : "Proxima etapa"
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-arrow-right icon-font",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "tab-content",
                          [
                            _c("dados-corretora", {
                              attrs: { broker: _vm.cadastro },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "tab-content",
                          { staticClass: "mb-5" },
                          [
                            _c("dados-bancarios", {
                              attrs: {
                                broker: _vm.cadastro,
                                bancos: _vm.bancos,
                                accountTypeList: _vm.accountTypeList,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "tab-content",
                          { staticClass: "mb-5" },
                          [
                            _c("dados-usuario", {
                              attrs: { cadastro: _vm.cadastro },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }