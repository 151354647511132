<template>
  <div class="dados-usuario">
    <div class="vx-row">
      <h4 class="vx-col w-full flex items-center font-semibold p-0">
        <span class="block">Dados dos Usuários</span>
        <vx-tooltip :text="text.textUsuario" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip>
      </h4>
    </div>
    <div class="vx-row" v-if="(cadastro.Users || []).length == 0">
      <div class="vx-col w-full p-0 mt-3">
        <vs-alert color="warning" class="h-auto mt-2">
          Clique no botão de adicionar usuários para cadastrar um novo usuário
          corretor.
        </vs-alert>
      </div>
    </div>
    <vs-row v-for="(corretor, index) in cadastro.Users" :key="index">
      <vs-col vs-lg="10" vs-sm="12" class="p-0">
        <vs-row class="m-0 p-0">
          <vs-col vs-sm="12" vs-lg="6">
            <vs-input
              label="Nome"
              size="large"
              v-model="corretor.Name"
              class="w-full"
            />
          </vs-col>
          <vs-col vs-sm="12" vs-lg="6">
            <ValidationProvider
              name="E-mail do usuário"
              rules="email"
              v-slot="{ errors }"
            >
              <vs-input
                label="E-mail"
                size="large"
                v-model="corretor.Email"
                class="w-full"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </vs-col>
          <vs-col vs-sm="12" vs-lg="6">
            <vs-input
              label="Celular para autenticação"
              size="large"
              v-mask="['(##) #####-####']"
              v-model="corretor.PhoneNumber"
              class="w-full"
            />
          </vs-col>
          <vs-col vs-sm="12" vs-lg="6">
            <p>Tipo Perfil</p>
            <v-select
              label="Name"
              size="large"
              placeholder="Tipo Perfil"
              :options="tiposUsuario"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="corretor.ProfileUniqueId"
              :reduce="perfil => perfil.UniqueId"
            />
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col vs-sm="12" vs-lg="2">
        <vs-button
          class="delete mt-4 ml-2"
          @click="removeCorretor(index)"
          color="primary"
          type="flat"
          size="large"
          icon="delete"
        ></vs-button>
      </vs-col>
    </vs-row>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          icon-after
          @click="addCorretor()"
          class="font-semibold mt-2 float-right"
          color="primary"
          type="flat"
          icon="add_circle"
        >
          Adicionar usuário
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "dados-usuario",
  props: ["cadastro"],
  directives: { mask },
  data() {
    return {
      perfis: [],
      text: {
        textUsuario:
          "Local para definir outros usuários para uso da plataforma Digital Onpoint. Esses usuários vão receber email definição de senha a partir do email informado."
      },

      profileHelpers: PerfilHelpers
    };
  },
 async mounted() {
   await this.obterTipoDeAcesso(this.profileHelpers.userTypeEnum.BROKER);
  },
  computed: {
    ...mapGetters("usuario-module", ["tiposUsuario"])
  },
  methods: {
    ...mapActions("usuario-module", ["obterTipoDeAcesso"]),

    addCorretor() {
      this.cadastro.Users.push(this.createCorretor());
    },

    removeCorretor(index) {
      this.cadastro.Users.splice(index, 1);
    },

    createCorretor() {
      return {
        _id: Math.random(),
        Name: "",
        Email: "",
        ProfileUniqueId: "",
        PhoneNumber: ""
      };
    }
  }
};
</script>
<style lang="scss">
.dados-usuario {
  h4 {
    color: rgba(var(--vs-primary), 1);

    i {
      color: rgba(var(--vs-warning), 1);
      display: block;
      font-size: 0.8em !important;
    }
  }
}
</style>
