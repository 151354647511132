//Mantido caso algo diferente tenha que ser feito futuramente
//Task 1440 - [Front] Remoção de step de auto-declaração PPE


<template>
  <div class="declarao-ppe">
    <div class="vx-row">
      <h4 class="vx-col w-full flex items-center font-semibold p-0">
        <span class="block">Auto declaração PPE</span>
        <vx-tooltip :text="text.textPPE" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip>
      </h4>
      <p>
      É considerada um Pessoa Politicamente Exposta (PPE) ou Pessoa Exposta Politicamente (PEP), aquela que desempenha ou desempenhou nos últimos cinco anos anteriores, no Brasil ou em países, territórios e dependências estrangeiros, cargos, empregos ou funções públicas relevantes.
      </p>
      <p>Algum dos sócios se enquadra como PPE?</p>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full mt-5 pl-0">
        <vs-switch class="w-1/12" v-model="cadastro.possuiPep">
          <span slot="on">Sim</span>
          <span slot="off">Não</span>
        </vs-switch>
      </div>
    </div>

    <div class="vx-row mt-5" v-if="cadastro.possuiPep">
      <h4 class="vx-col w-full flex items-center font-semibold p-0">
        <span class="block">Sócios/Administradores PEP</span>
        <i class="material-icons pl-2">info</i>
      </h4>
      <p>Cadastre abaixo a lista dos sócios que se enquadra como PPE.</p>
    </div>
    <template v-if="cadastro.possuiPep">
      <div class="vx-row"
        v-for="(socio, index) in cadastro.QSAs" :key="index">
        <div class="vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0">
          <ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
            <vs-input label-placeholder="Nome"
            size="large" v-model="socio.Name" class="w-full" />
            <span class="text-danger text-sm" v-show="errors[0] ">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>
        <div class="vx-col md:w-4/12 w-full mt-5 md:pr-0 md:pl-2 p-0">
          <ValidationProvider name="CPF" rules="required" v-slot="{ errors }">
            <vs-input label-placeholder="CPF"
            size="large" v-model="socio.CpfCnpj"
            v-mask="'###.###.###-##'" class="w-full" />
            <span class="text-danger text-sm" v-show="errors[0] ">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>
        <div class="vx-col mt-10 pr-0">
          <vs-button class="delete"
            @click="removeSocio(index)"
            color="primary" type="flat" size="large" icon="delete"></vs-button>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button
            icon-after
            @click="addSocio()"
            class="font-semibold mt-2 float-right"
            color="primary" type="flat" icon="add_circle">
            Adicionar sócio
          </vs-button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";

export default {
  name: "declaracao-ppe",
  props: {
    cadastro: {
      type: Object,
      required: true
    }
  },
  directives: {mask},
  data () {
    return {
      text: {
        textPPE: "Por regulamentação da SUSEP, através da Circular 445/12, é necessário que a Corretora declare se possui em seu quadro societário pessoas politicamente expostas!"
      }
    };
  },
  methods: {
    addSocio () {
      this.cadastro.QSAs.push(this.createSocio());
    },
    removeSocio (index) {
      this.cadastro.QSAs.splice(index, 1);
    },
    createSocio () {
      return {
        _id: Math.random(),
        Name: "",
        Email: "",
        CpfCnpj: ""
      };
    }
  }
};
</script>
<style lang="scss">
.declarao-ppe {
  h4 {
    color: rgba(var(--vs-primary), 1);

    i {
      color: rgba(var(--vs-warning), 1);
      display: block;
      font-size: .8em !important;
    }
  }
}
</style>